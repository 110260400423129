<template>
  <v-dialog v-model="dialog" scrollable width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>Ver histórico</v-list-item-title>
      </v-list-item>
    </template>

    <v-card style="overflow-x:hidden">
      <v-card-title>
        Histórico de alterações
      </v-card-title>

      <v-card-subtitle>
        <span v-if="loading">Carregando</span>
        <span v-else>{{ history.name }} - {{ item.offer_type }}</span>
      </v-card-subtitle>

      <v-card-text v-if="loading" class="d-flex justify-center">
        <v-progress-circular v-if="loading" indeterminate color="primary" />
      </v-card-text>

      <v-card-text v-if="!loading">
        <v-list>
          <v-list-item v-for="(log, index) in history.logs" :key="`log-item${index}`" class="log-item">
            <v-list-item-content>
              <v-list-item-title class="history-log-title">
                <mf-icon fill :color="log.iconColor" size="20" class="ml-2">{{ log.icon }}</mf-icon>
                <span :style="{ color: log.typeTextColor }">{{ log.formattedType }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="mb-2">{{ log.date | date }}</v-list-item-subtitle>

              <div class="log-details">
                <span v-if="log.user"><strong>Usuário:</strong> {{ log.user.name }} ({{ log.user.email }}) </span>
                <span v-else><strong>Usuário:</strong> DAG </span>
                <span v-if="log.error"><strong>Erro:</strong> {{ log.error.message }}</span>
                <span v-if="log.alert"><strong>Alerta:</strong> {{ log.alert.message }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <mf-action-buttons
        class="mb-3 mr-3"
        :secondary-button="{
          text: 'Fechar',
          action: () => (dialog = false),
          isVisible: true
        }"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { ENUM_MODALITY, ENUM_STATUS, ENUM_ACTIONS } from '@/modules/industry/enums'
import { QUERY_INDUSTRY_OFFER_LOG } from '@/modules/industry/graphql'

export default {
  name: 'HistoryView',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    modality: {
      type: String,
      default: 'none',
      validator: value => [ENUM_MODALITY.MERCAPROMO, ENUM_MODALITY.MERCATRADE, 'none'].includes(value)
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    history: {
      action: '',
      logs: [],
      name: ''
    }
  }),
  watch: {
    dialog(newValue) {
      if (!newValue) return
      this.getLogs()
    }
  },
  methods: {
    async getLogs() {
      this.loading = true
      try {
        const response = await this.$apollo.query({
          query: QUERY_INDUSTRY_OFFER_LOG,
          fetchPolicy: 'network-only',
          variables: {
            industryOfferId: this.item._id,
            modality: this.modality
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        this.history = response.data.industryOffer
        this.history.logs = this.sortLogsByDate(
          response.data.industryOffer.logs.map(log => {
            return {
              ...log,
              formattedType: this.translateLogType(log.type),
              typeTextColor: this.logStyle(log.type).typeTextColor,
              iconColor: this.logStyle(log.type).iconColor,
              icon: this.logStyle(log.type).icon
            }
          })
        )
      } catch (error) {
        this.$snackbar({ message: 'Erro ao carregar histórico da oferta.', snackbarColor: 'error' })
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    sortLogsByDate(logs) {
      return logs.sort((a, b) => new Date(b.date) - new Date(a.date))
    },

    translateLogType(logType) {
      switch (logType) {
        case 'create':
          return 'Oferta criada'
        case 'create_success':
          return 'Oferta enviada para o varejista com sucesso'
        case 'create_error':
          return 'Falha no envio da oferta'
        case 'create_missing_products':
          return 'Oferta enviada para o varejista com produtos faltantes'
        case 'update':
          return 'Oferta editada'
        case 'update_success':
          return 'Edição sincronizada com o varejista'
        case 'update_error':
          return 'Falha no envio da edição'
        case 'update_missing_products':
          return 'Editado com produtos faltantes'
        case 'delete':
          return 'Oferta pronta para remoção'
        case 'delete_success':
          return 'Oferta removida do varejista com sucesso'
        case 'delete_error':
          return 'Falha na remoção da oferta'
        case 'reprocess':
          return 'Reprocessamento solicitado'
        default:
          return 'Tipo de log desconhecido'
      }
    },

    logStyle(logType) {
      if (logType === 'reprocess') {
        return {
          iconColor: '#FFC107',
          icon: 'autorenew',
          typeTextColor: '#FFA000'
        }
      }
      let [action, status, alert] = logType.split('_')
      status = status ?? 'pending'

      if (alert) {
        status += `_${alert}`
      }

      let pendingIcon = ''
      switch (action) {
        case ENUM_ACTIONS.CREATE:
          pendingIcon = 'add_circle'
          break
        case ENUM_ACTIONS.UPDATE:
          pendingIcon = 'edit'
          break
        case ENUM_ACTIONS.DELETE:
          pendingIcon = 'delete'
          break
        default:
          pendingIcon = ''
          break
      }

      switch (status) {
        case ENUM_STATUS.PENDING:
          return {
            iconColor: '#0063C6',
            icon: pendingIcon,
            typeTextColor: '#004D99'
          }
        case ENUM_STATUS.SUCCESS:
          return {
            iconColor: '#0DA740',
            icon: 'check_circle',
            typeTextColor: '#086A29'
          }
        case ENUM_STATUS.MISSING_PRODUCTS:
          return {
            iconColor: '#F2803F',
            icon: 'check_circle',
            typeTextColor: '#C65718'
          }
        case ENUM_STATUS.ERROR:
          return {
            iconColor: '#E03210',
            icon: 'warning',
            typeTextColor: '#B3280D'
          }
        default:
          return {
            iconColor: '#000000',
            icon: 'unknown_med',
            typeTextColor: '#000000'
          }
      }
    }
  }
}
</script>

<style>
.log-item {
  background-color: #f5f5f5;
  border-radius: 12px;
  margin: 12px 0;

  .log-details {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #6b7580;
  }

  .history-log-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-left: -8px;
  }
}
</style>
