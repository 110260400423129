var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("Ver histórico")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',[_vm._v(" Histórico de alterações ")]),_c('v-card-subtitle',[(_vm.loading)?_c('span',[_vm._v("Carregando")]):_c('span',[_vm._v(_vm._s(_vm.history.name)+" - "+_vm._s(_vm.item.offer_type))])]),(_vm.loading)?_c('v-card-text',{staticClass:"d-flex justify-center"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1):_vm._e(),(!_vm.loading)?_c('v-card-text',[_c('v-list',_vm._l((_vm.history.logs),function(log,index){return _c('v-list-item',{key:("log-item" + index),staticClass:"log-item"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"history-log-title"},[_c('mf-icon',{staticClass:"ml-2",attrs:{"fill":"","color":log.iconColor,"size":"20"}},[_vm._v(_vm._s(log.icon))]),_c('span',{style:({ color: log.typeTextColor })},[_vm._v(_vm._s(log.formattedType))])],1),_c('v-list-item-subtitle',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm._f("date")(log.date)))]),_c('div',{staticClass:"log-details"},[(log.user)?_c('span',[_c('strong',[_vm._v("Usuário:")]),_vm._v(" "+_vm._s(log.user.name)+" ("+_vm._s(log.user.email)+") ")]):_c('span',[_c('strong',[_vm._v("Usuário:")]),_vm._v(" DAG ")]),(log.error)?_c('span',[_c('strong',[_vm._v("Erro:")]),_vm._v(" "+_vm._s(log.error.message))]):_vm._e(),(log.alert)?_c('span',[_c('strong',[_vm._v("Alerta:")]),_vm._v(" "+_vm._s(log.alert.message))]):_vm._e()])],1)],1)}),1)],1):_vm._e(),_c('mf-action-buttons',{staticClass:"mb-3 mr-3",attrs:{"secondary-button":{
        text: 'Fechar',
        action: function () { return (_vm.dialog = false); },
        isVisible: true
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }